import React from "react";
import { CheckoutSuccessPage, Head } from "component-library";
import CheckoutPageWrapper from "./CheckoutPageWrapper/CheckoutPageWrapper";
import { useTranslation } from "i18n";
import { useParams } from "react-router-dom";
import {
  BillingUser,
  BillingUserWithoutCommon,
} from "translations/src/models/billing";

const CheckoutComplete = () => {
  const { t } = useTranslation();

  const { userType } = useParams<{ userType: string }>();

  const textOverrides =
    userType === BillingUser.GraduateTransfer
      ? {
          common: {
            successHeader: t(
              "common:graduateTransfer.checkout.completePage.successHeader"
            ),
            directDebitSuccessHeader: t(
              "common:graduateTransfer.checkout.completePage.directDebitSuccessHeader"
            ),
            successBody: t(
              "common:graduateTransfer.checkout.completePage.successBody"
            ),
            directDebitSuccessBody: t(
              "common:graduateTransfer.checkout.completePage.directDebitSuccessBody"
            ),
          },
        }
      : {};

  return (
    <>
      <Head>
        <title>{t("common:billing.checkoutComplete.pageTitle")}</title>
      </Head>
      <CheckoutPageWrapper>
        <CheckoutSuccessPage
          textOverrides={textOverrides}
          userType={userType as BillingUserWithoutCommon}
        />
      </CheckoutPageWrapper>
    </>
  );
};

export default CheckoutComplete;
