import React from "react";
import { useHistory } from "react-router-dom";
import { BillingContextProvider, CDCheckout } from "component-library";
import { logCustomEvent as brazeCustomEvent } from "@braze/web-sdk";
import { CheckoutAccessTypes } from "component-library/src/components/Organisms/Billing/types";
import applicationMap from "services/applicationMap";
import { useGetBillingContextDefaultProps } from "../hooks/useGetBillingContextDefaultProps";
import { CheckoutBrazeEventTypes } from "pages/CheckoutPageWrapper/types";

interface Props extends CheckoutBrazeEventTypes {}

export default function CDWrapper({ brazeSubscriptionPurchaseType }: Props) {
  const history = useHistory();
  const defaultProps = useGetBillingContextDefaultProps(
    CheckoutAccessTypes.RenewalReactivation
  );

  const postCheckoutCallback = async (
    _: any,
    trackingData: Record<string, any>
  ) => {
    brazeCustomEvent(brazeSubscriptionPurchaseType, {
      memberType: trackingData.memberType,
      paymentPeriod: trackingData.paymentPeriod,
      revenue: trackingData.paymentValue,
    });
  };

  return (
    <BillingContextProvider
      {...defaultProps}
      postCheckoutCallback={postCheckoutCallback}
      goBack={() => history.push(applicationMap.routes.index())}
    >
      <CDCheckout isRenewalReactivation />
    </BillingContextProvider>
  );
}
