import { AccountType } from "component-library";

import config from "config/global";
import CDWrapper from "pages/CheckoutPageWrapper/variants/CDWrapper";
import GradTransferWrapper from "pages/CheckoutPageWrapper/variants/GradTransferWrapper";
import PerformerWrapper from "pages/CheckoutPageWrapper/variants/PerformerWrapper";
import { BillingUser } from "translations/src/models/billing";

export const PROJECT_API_NAME = "useraccountapi";

export const DEFAULT_LOCALE = "en-GB";
export const BASE_PATH = "/myhome"; // TODO replace with base url
export const SCROLLING_CONTAINER_ID = "scrollingContainer";

export enum ErrorCode {
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  UnprocessableEntity = 422,
  InternalServerError = 500,
}

export const ACCOUNT_TYPE_GTM_MAPPING = {
  [AccountType.Agent]: config.gtm.accountTypes.agent,
  [AccountType.Performer]: config.gtm.accountTypes.performer,
  [AccountType.CastingDirector]: config.gtm.accountTypes.castingDirector,
};

export enum ErrorPopupType {
  System = "systemError",
  Account = "accountError",
  Access = "accountAccessError",
  Network = "networkError",
}

export const AccountTypeBillingTypeMap = {
  castingDirector: BillingUser.Casting,
  "castingDirector:searchOnlyCastingDirector": BillingUser.Casting,
  performer: BillingUser.Performer,
  "performer:youngPerformer": BillingUser.YoungPerformer,
  "performer:graduate": BillingUser.Graduate,
  // Transferring grads are main perfs; handled in useGetUserType
};

export type NonJoiningBillingUser = Exclude<
  BillingUser,
  | BillingUser.JoiningCasting
  | BillingUser.JoiningPerformer
  | BillingUser.JoiningYoungPerformer
  | BillingUser.JoiningGraduate
  | BillingUser.Common
>;

export const renderComponentMap: Record<NonJoiningBillingUser, any> = {
  [BillingUser.Casting]: CDWrapper,
  [BillingUser.Performer]: PerformerWrapper,
  [BillingUser.YoungPerformer]: PerformerWrapper,
  [BillingUser.GraduateTransfer]: GradTransferWrapper,
  [BillingUser.Graduate]: PerformerWrapper,
};
