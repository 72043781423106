const performerBaseLinks = {
  updateProfile: {
    text: "common:nav.links.viewUpdateProfile",
    href: "/profile",
  },
  memberEvents: {
    text: "common:nav.links.memberEvents",
    href: "https://www.spotlight.com/the-small-print/spotlight-performer-events/",
  },
  jobs: {
    text: "common:nav.links.jobsFeed",
    href: "/jobs/matching-my-filters",
  },
  memberAssist: {
    text: "common:nav.links.memberAssistProgram",
    href: "https://www.spotlight.com/the-small-print/wellbeing-in-the-arts-terms/",
  },
  newsAdvice: {
    text: "common:nav.links.newsAdvice",
    href: "https://www.spotlight.com/news-and-advice",
  },
  healthWellbeing: {
    text: "common:nav.links.healthAndWellbeingHub",
    href: "https://www.spotlight.com/news-and-advice/supporting-your-mental-health-and-wellbeing/",
  },
  contacts: {
    text: "common:nav.links.contacts",
    href: "https://www.spotlight.com/contacts",
  },
  faq: {
    text: "common:nav.links.faq",
    href: "https://www.spotlight.com/help-and-faqs",
  },
};

export const performersDefaultQuickLinks = [
  performerBaseLinks.updateProfile,
  performerBaseLinks.memberEvents,
  {
    text: "common:nav.links.filmmakers",
    href: "https://www.spotlight.com/members/expand-your-horizons-with-filmmakers-premium/",
  },
  {
    text: "common:nav.links.ecp",
    href: "https://www.spotlight.com/news-and-advice/spotlight-launches-extra-cover-pack/",
  },
  performerBaseLinks.jobs,
  performerBaseLinks.memberAssist,
  performerBaseLinks.newsAdvice,
  performerBaseLinks.healthWellbeing,
  performerBaseLinks.contacts,
  performerBaseLinks.faq,
];

export const filmmakersQuickLinks = [
  {
    text: "common:nav.links.filmmakers",
    href: "https://www.spotlight.com/news-and-advice/spotlight/filmmakers-details/",
  },
  performerBaseLinks.jobs,
  performerBaseLinks.newsAdvice,
  performerBaseLinks.healthWellbeing,
  performerBaseLinks.contacts,
  performerBaseLinks.faq,
];

export const performersGraduatesQuickLinks = [
  performerBaseLinks.memberEvents,
  performerBaseLinks.updateProfile,
  performerBaseLinks.jobs,
  performerBaseLinks.memberAssist,
  performerBaseLinks.newsAdvice,
  performerBaseLinks.healthWellbeing,
  performerBaseLinks.contacts,
  performerBaseLinks.faq,
];

export const performersYPQuickLinks = [
  performerBaseLinks.updateProfile,
  {
    text: "common:nav.links.support",
    href: "https://www.spotlight.com/news-and-advice/tips-and-advice/young-performer-resources-industry-hub/",
  },
  {
    text: "common:nav.links.faq",
    href: "https://www.spotlight.com/help-and-faqs/faqs-for-young-performers/",
  },
  performerBaseLinks.memberEvents,
  performerBaseLinks.newsAdvice,
  performerBaseLinks.contacts,
];

export const performersAddOnBundleQuickLinks = [
  performerBaseLinks.memberEvents,
  performerBaseLinks.updateProfile,
  performerBaseLinks.jobs,
  performerBaseLinks.memberAssist,
  performerBaseLinks.newsAdvice,
  performerBaseLinks.healthWellbeing,
  performerBaseLinks.contacts,
  performerBaseLinks.faq,
];

export const performersInactiveQuickLinks = [
  performerBaseLinks.newsAdvice,
  performerBaseLinks.contacts,
  performerBaseLinks.faq,
];
