import React, { useState } from "react";
import { TFunction } from "i18n";
import { authorizationService } from "shared-auth";
import { AddOnEntitlements } from "shared-types";
import { getFilmmakersSSOLink } from "shared-utils";
import { ReactComponent as FilmmakersProfileIcon } from "component-library/src/images/svg/filmmakers-logo-black.svg";
import { Pill } from "../../../../Atoms/Pill/Pill";
import {
  NotificationToast,
  NotificationToastTypes,
} from "../../../../Atoms/NotificationToast/NotificationToast";

export function getMediaRequestsSubNav(isMediaRequestEnabled?: boolean) {
  return isMediaRequestEnabled
    ? [
        {
          text: "common:nav.links.mediaRequests",
          linkUrl: "/jobs/self-tape-requests",
          renderLabel: (t: TFunction) => (
            <span>
              {t("common:nav.links.mediaRequests")}{" "}
              <Pill className="bg-blue-sky-2 ml-1.5" theme="light" size="md">
                {t("common:nav.links.pill.beta")}
              </Pill>
            </span>
          ),
        },
      ]
    : [];
}

export function getFilmmakersJobsLink(isFilmmakersPhase4Enabled?: boolean) {
  const hasFilmmakerEntitlements =
    authorizationService.doesUserHaveEntitlements(
      AddOnEntitlements.FilmmakersSync
    );

  return hasFilmmakerEntitlements && isFilmmakersPhase4Enabled
    ? [
        {
          text: "",
          linkUrl: "",
          handleClick: async (e: React.MouseEvent<HTMLAnchorElement>) =>
            e.preventDefault(),
          renderLabel: (t: TFunction) => {
            const [showError, setShowError] = useState<boolean>(false);
            const handleRedirect = async () => {
              try {
                const data = await getFilmmakersSSOLink(
                  "https://www.filmmakers.eu/casting_calls"
                );

                window.open(data.url, "_blank");
              } catch (e) {
                setShowError(true);
              }
            };

            return (
              <>
                <button className="flex gap-1.5" onClick={handleRedirect}>
                  {t("common:nav.links.filmmakers.viewJobs")}{" "}
                  <FilmmakersProfileIcon height={23} width={100} />
                </button>
                <NotificationToast
                  stickToComponent={false}
                  handleHide={() => setShowError(false)}
                  show={showError}
                  type={NotificationToastTypes.Error}
                  text={t("common:errorPopup.httpStatus.generic.body")}
                  className="w-auto"
                />
              </>
            );
          },
        },
      ]
    : [];
}
