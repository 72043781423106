import React, { FC } from "react";
import cx from "classnames";
import { useTranslation } from "i18n";

import { Button, Pill } from "component-library";
import { dataTestIds } from "data-testids";
import {
  AddOnCodes,
  addOnMoreInfoLinkMap,
  addOnNamesMap,
} from "component-library/src/components/Organisms/Billing/constants";
import { ReactComponent as OpenInNewTabIcon } from "component-library/src/images/svg/icons/open-in-new-tab.svg";
import { AddOnCalculatedStatus, buyAddOnButtonNamesMap } from "app/constants";
import BannerMessage from "components/BannerMessage";
import { getStatusPillClassNames } from "pages/MySubscriptions/MySubscriptions";
import { IAddOn } from "services/api/BillingApi/BillingApi";

interface IAddOnSectionProps {
  addOn: IAddOn;
  isSubscriptionActive: boolean;
  onCancelAddOnClick: () => void;
  onBuyAddOnClick: (code: AddOnCodes) => void;
}

const buttonClassNames = "w-full px-3 text-sm font-normal md:w-auto";

const AddOnSection: FC<IAddOnSectionProps> = ({
  addOn,
  isSubscriptionActive,
  onCancelAddOnClick,
  onBuyAddOnClick,
}) => {
  const { t } = useTranslation();
  const { description, calculatedStatus, availableToBuy, code, cancelled } =
    addOn;
  const isActive = calculatedStatus === AddOnCalculatedStatus.Active;
  const isFilmMakersAddOn =
    code === AddOnCodes.FilmMakersAnnual ||
    code === AddOnCodes.FilmMakersMonthly;
  const showCancelButton = isActive && isFilmMakersAddOn && !cancelled;
  const isDisabled = !availableToBuy || !isSubscriptionActive;

  return (
    <div
      key={code}
      className="flex flex-col gap-2 p-3 rounded md:flex-none bg-color-neutral-three"
    >
      <div className="flex flex-row gap-2 md:items-center md:gap-4">
        <h6 className="font-semibold">{t(addOnNamesMap[code])}</h6>
        <Pill
          size="md"
          className={getStatusPillClassNames(isActive)}
          theme="light"
        >
          {isActive ? t("common:label.active") : t("common:label.inactive")}
        </Pill>
      </div>
      <p>{description}</p>
      <div
        className={cx("flex flex-col items-center justify-between gap-2", {
          "md:flex-row": !cancelled,
        })}
      >
        <div className="flex items-center self-start gap-1">
          <a
            href={addOnMoreInfoLinkMap[addOn.code]}
            target="_blank"
            className="text-sm font-semibold leading-snug md:text-base"
          >
            {t("common:label.moreInformation")}
          </a>
          <OpenInNewTabIcon className="text-color-brand-one" />
        </div>
        {cancelled && (
          <BannerMessage
            title={t(
              "performer:accountSettings.addOns.cancelledBannerMessage.title"
            )}
            description={t(
              "performer:accountSettings.addOns.cancelledBannerMessage.description"
            )}
          />
        )}
        {showCancelButton && (
          <Button
            type="secondary"
            size="small"
            text={t("common:button.label.cancelAddOn")}
            onClick={onCancelAddOnClick}
            className={buttonClassNames}
          />
        )}
        {!cancelled && !showCancelButton && (
          <Button
            type="primary"
            size="small"
            className={buttonClassNames}
            text={t(buyAddOnButtonNamesMap[code])}
            onClick={() => onBuyAddOnClick(code)}
            disabled={isDisabled}
            data-testid={
              dataTestIds.userAccountApp["MySubscription.BuyAddOn.button"]
            }
          />
        )}
      </div>
    </div>
  );
};

export default AddOnSection;
