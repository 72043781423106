import React, { useEffect } from "react";
import { useTranslation } from "i18n";
import { useHistory, useParams } from "react-router-dom";
import applicationMap, {
  isAcceptedCheckoutType,
} from "services/applicationMap";
import { Head } from "component-library";
import { useCanAccessCheckout } from "component-library/src/components/Organisms/Billing/hooks/useCanAccessCheckout";
import { USER_ACCOUNT_APP_ROOT } from "component-library/src/components/Organisms/Billing/constants";
import CheckoutPageWrapper from "../CheckoutPageWrapper/CheckoutPageWrapper";

const Checkout = () => {
  const { type } = useParams<{ type: string }>();
  const history = useHistory();

  useEffect(() => {
    if (!isAcceptedCheckoutType(type)) {
      return history.replace(applicationMap.routes.index());
    }
  }, [type]);

  const { t } = useTranslation();

  useCanAccessCheckout(true, USER_ACCOUNT_APP_ROOT);

  return (
    <>
      <Head>
        <title>{t("common:billing.checkout.pageTitle")}</title>
      </Head>
      <CheckoutPageWrapper />
    </>
  );
};

export default Checkout;
