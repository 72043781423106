import { useState, useEffect } from "react";
import { CheckoutAccessTypes } from "component-library/src/components/Organisms/Billing/types";
import {
  BillingUser,
  BillingUserWithoutCommon,
} from "translations/src/models/billing";
import { authorizationService } from "shared-auth";
import { AccountTypeBillingTypeMap } from "config/common";

export default function useGetUserType(checkoutFlow: CheckoutAccessTypes) {
  const [userType, setUserType] = useState<BillingUserWithoutCommon>();

  useEffect(() => {
    if (checkoutFlow === CheckoutAccessTypes.GradsTransfer) {
      // user will not have graduate member group when entering conversion flow
      return setUserType(BillingUser.GraduateTransfer);
    }
    authorizationService.getUser().then((user) => {
      if (!userType && user) {
        setUserType(
          AccountTypeBillingTypeMap[user.profile["spotlight.MemberGroups"]]
        );
      }
    });
  }, [checkoutFlow, userType]);

  return userType;
}
